<template>
  <PullRefresh :on-refresh="onRefresh">
    <v-container v-if="$store.state.me" class="home text-left">
      <v-row class="mx-0">
        <!-- Pilot name & Flight hours banner -->
        <v-col cols="12" class="px-0">
          <div class="card-pilot d-flex fill-width align-center">
            <v-row no-gutters class="justify-space-between">
              <v-col cols="10" class="align-self-center">
                <div class="pilot-name mb-2">
                  <div class="align-middle">
                    {{ Greeting() }}
                  </div>
                  <div v-if="$store.state.admin" class="fw-700">
                    <v-autocomplete
                      v-model.number="adminPilotSelected"
                      :items="$store.state.logbook.pilots"
                      item-text="fullname"
                      item-value="id"
                      :loading="!$store.state.logbook.pilots.length"
                      hide-details
                      color="white"
                      :placeholder="!$store.state.logbook.pilots.length ? 'Please Wait' : 'Select Pilot'"
                      class="home-search-pilot white--text"
                    />
                  </div>
                  <div v-else class="fw-700">
                    {{ $store.state.me.name + ' ' + $store.state.me.name_last }}
                  </div>
                </div>
                <div class="pilot-hours">
                  <span class="hours">
                    {{ $store.state.me_flighttime ? $store.state.me_flighttime : 0 }}
                  </span>
                  <span>
                    Total Flight Hours
                  </span>
                </div>
              </v-col>
              <v-col cols="auto" class="align-self-center text-right">
                <div class="img" @click.prevent="$router.push('/?editprofile=1')">
                  <img :src="$store.state.me.img || '/images/icons/ic-pilot.png'" class="rounded-circle" onerror="this.onerror=null;this.src='/images/icons/ic-pilot.png';"/>
                  <div class="pilot-status" :class="$store.state.me.status_duty === 0 ? 'off' : $store.state.me.status_duty === 2 ? 'sick' : ''"></div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- End of Pilot name & Flight hours banner -->

        <!-- Upcoming Flights Component -->
        <v-col cols="12" class="upcoming-flights">
          <v-row no-gutters class="justify-space-between">
            <v-col cols="auto">
              <span class="font-20 fw-600">Upcoming Flights</span>
            </v-col>
            <v-col cols="auto">
              <router-link to="/upcoming-schedule" replace>
                <span class="font-14">See More</span>
              </router-link>
            </v-col>
          </v-row>

          <!-- Swiper of Upcoming Flight Card -->
          <div v-if="upcomingSchedule.length && upcomingSchedule.filter(r => !r.afl_signedby_id).length" v-bind="flightSwiper()" class="swiper upcomingFlightSwiper">
            <div class="swiper-wrapper">
              <div v-for="(s, iSchedule) in upcomingSchedule.filter(r => !r.afl_signedby_id)" :key="iSchedule" class="swiper-slide">
                <v-card class="upcoming-flight-card mr-4" @click.prevent="flightDetail(s.duty_local_date)">
                  <div class="flight-date">
                    <div class="flight-day">
                      {{ $moment(s.duty_local_date).format('D') }}
                    </div>
                    <div class="flight-month-year">
                      {{ $moment(s.duty_local_date).format('MMM YYYY') }}
                    </div>
                    <div class="flight-hour">
                      {{ s.etd_local_time }} LT
                    </div>
                  </div>
                  <img src="@/assets/media/route-symbol.svg" alt="Route Symbol">
                  <div v-if="s.flight_type === 'simulator'" class="flight-route-container">
                    <div class="city-departure">
                      Simulator Flight
                    </div>
                    <div class="airport-name-iata mb-4">
                      at Simulator Room
                    </div>
                    <div class="city-departure">
                      Duration : {{ s.flighttime_decimal }} {{ parseInt(s.flighttime_decimal) > 1 ? 'Hours' : 'Hour' }}
                    </div>
                    <div class="airport-name-iata">
                      Until : {{ s.eta_local_time }} LT
                    </div>
                  </div>
                  <div v-else class="flight-route-container">
                    <div class="city-departure">
                      {{ s.dep_cityname }}
                    </div>
                    <div class="airport-name-iata mb-4">
                      {{ s.dep_name }} ({{ s.dep_iata }})
                    </div>
                    <div class="city-departure">
                      {{ s.arr_cityname }}
                    </div>
                    <div class="airport-name-iata">
                      {{ s.arr_name }} ({{ s.arr_iata }})
                    </div>
                  </div>
                </v-card>
              </div>
              <div v-if="upcomingSchedule.length === 5" class="swiper-slide see-more-swiper">
                <v-card class="more-flight-card" @click="$router.replace('schedule')">
                  <img src="@/assets/illustrations/aircraft-side.svg" alt="More Flights" width="50%">
                  <div>
                    <span class="align-middle">
                      More Upcoming Flight
                    </span>
                    <v-icon small color="primary" class="align-middle ml-1">
                      mdi-chevron-right-circle
                    </v-icon>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
          <div v-else class="noFlightCard my-4">
            <EmptyFlight />
            <div>No Upcoming Flight</div>
          </div>
          <!-- End of Swiper -->
        </v-col>
        <!-- End of Upcoming Flights Component -->

        <!-- News Component -->
        <v-col cols="12" class="news-info">
          <v-row no-gutters class="justify-space-between">
            <v-col cols="auto" class="align-self-center">
              <span class="font-20 fw-600 align-middle">Latest News</span>
            </v-col>
            <v-col cols="auto" class="align-self-center">
              <router-link to="/news" replace>
                <span class="font-14">See More</span>
              </router-link>
            </v-col>
          </v-row>

          <!-- Swiper of News & Info -->
          <div v-if="newsData.length" v-bind="newsSwiper()" class="swiper newsInfoSwiper">
            <div class="swiper-wrapper">
              <div v-for="(data, index) in (newsData).slice(0, 5)" class="swiper-slide newsSlide" :key="index">
                <v-card class="news-info-card" @click="$router.push('/news?title=' + data.slug)">
                  <img :src="`${data.cover}`" alt="News Thumb">
                  <div>
                    <div>
                      {{ data.title }}
                    </div>
                  </div>
                </v-card>
              </div>
              <div v-if="newsData.length >= 5" class="swiper-slide see-more-swiper">
                <v-card class="more-flight-card" @click="$router.replace('news')">
                  <img src="@/assets/illustrations/more-news.svg" alt="More News" width="30%">
                  <div>
                    <span class="align-middle">
                      More News
                    </span>
                    <v-icon small color="primary" class="align-middle ml-1">
                      mdi-chevron-right-circle
                    </v-icon>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
          <div v-else @click="$router.push('/news')" class="news-info-blank">
            <img src="../assets/illustrations/blank_news.svg" alt="No News" />
          </div>
          <!-- End of Swiper -->
        </v-col>
        <!-- End of News Component -->

        <!-- Forecast Component -->
        <v-col cols="12" class="px-0 mb-4">
          <v-row no-gutters class="justify-space-between mb-3">
            <v-col cols="auto" class="align-self-center">
              <span class="font-20 fw-600 align-middle">
                Nearby Forecast
              </span>
            </v-col>
            <v-col cols="auto" class="align-self-center">
              <v-icon color="primary" class="font-20" @click.prevent="reloadForecast()">
                mdi-reload
              </v-icon>
            </v-col>
          </v-row>
          <Forecast v-if="showForecast" :lat="$store.state.me.pos_latitude" :long="$store.state.me.pos_longitude" />
        </v-col>
        <!-- End of Forecast Component -->

        <!-- Static Flight Time -->
        <v-col cols="12" class="my-stats">
          <v-row no-gutters>
            <v-col cols="auto" class="align-self-center">
              <div class="font-20 fw-600">My Statistics</div>
            </v-col>
            <v-col v-if="!loadingData" cols="auto" class="align-self-center pl-1">
              <!-- This button will refresh Flight Time Data -->
              <v-btn depressed icon color="primary" @click.prevent="loadChartInit()">
                <v-icon class="font-20">
                  mdi-refresh
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-card class="stat-list-card mt-4">
            <div class="today-stat">
              <v-row no-gutters class="justify-space-between">
                <v-col cols="auto" class="align-self-center">
                  <div class="font-16 fw-700 mb-1">
                    {{ todayLimit.title }}
                  </div>
                  <div>{{ $moment().format('D MMM YYYY') }}</div>
                </v-col>
                <v-col cols="auto" class="align-self-center text-right">
                  <div class="font-28 fw-500">{{ todayLimit.logbook ? parseFloat(todayLimit.logbook).toFixed(1) : '0.0' }} h</div>
                </v-col>
              </v-row>
              <div v-if="todayLimit.logbook > 0" class="limit-flight-hours text-right"
                :class="todayLimit.logbook >= todayLimit.limitHours ? 'limit-reached' : ''">
                {{ todayLimit.limitHours > todayLimit.logbook ? parseFloat(todayLimit.limitHours - todayLimit.logbook).toFixed(1) + ' hours to limit' : parseFloat(todayLimit.limitHours).toFixed(1) ==
        parseFloat(todayLimit.logbook).toFixed(1) ? 'Flight hours limit reached' : '+ ' +
        parseFloat(todayLimit.logbook - todayLimit.limitHours).toFixed(1) + ' hours from the limit' }}
              </div>
            </div>
            <div class="last-month-stat">
              <div v-bind="statsSwiper()" class="swiper statsSwiper">
                <div class="swiper-wrapper">
                  <div v-for="(data, index) in pastLimit" :key="index" class="swiper-slide">
                    <v-row no-gutters class="justify-space-between">
                      <v-col cols="auto" class="align-self-center">
                        <div class="font-16 fw-700 mb-1">
                          {{ data.title }}
                        </div>
                        <div>{{ $moment().subtract(data.daysValue, 'days').format('D MMM YYYY') }} - Today</div>
                      </v-col>
                      <v-col cols="auto" class="align-self-center text-right">
                        <div v-if="(data.daysValue === 90 || data.daysValue === 180 || data.daysValue === 365) && data.logbook === null">
                          <v-icon v-if="!isLoadingStaticChart" color="white" @click.prevent="toggleLoadStaticChartData(data.daysValue)">
                            mdi-refresh
                          </v-icon>
                          <v-progress-circular v-else color="white" indeterminate />
                        </div>
                        <div v-else class="font-28 fw-500">
                          {{ data.logbook ? parseFloat(data.logbook).toFixed(1) : '0.0' }} h
                        </div>
                      </v-col>
                    </v-row>
                    <div class="limit-flight-hours text-right mt-2">
                      {{ data.limitHours > data.logbook ? parseFloat(data.limitHours - data.logbook).toFixed(1) + ' hours to limit' : parseFloat(data.limitHours).toFixed(1) === parseFloat(data.logbook).toFixed(1) ? 'Flight hours limit reached' : '+ ' + parseFloat(data.logbook - data.limitHours).toFixed(1) + ' hours from the limit' }}
                    </div>
                  </div>
                </div>
                <div class="prev-stats">
                  <v-icon small>
                    mdi-chevron-left
                  </v-icon>
                </div>
                <div class="next-stats">
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                </div>
              </div>
            </div>
          </v-card>

          <v-card class="stats-card mt-4">
            <!-- Show this loading component when loading flight time data -->
            <div v-if="loadingData" class="loading-container">
              <Loading show="true" color="white" />
            </div>

            <div v-else-if="chartData.show">
              <v-row no-gutters class="stats-summary-row">
                <v-col cols="12" class="align-self-center">
                  <div class="font-16 fw-700">
                    {{ statsEntity.title }}
                  </div>
                  <div v-if="$moment(minDate, 'YYYY-MM-DD').format('MMM') === $moment(maxDate, 'YYYY-MM-DD').format('MMM')">
                    {{ `${$moment(minDate, 'YYYY-MM-DD').format('D')} - ${$moment(maxDate).format('D MMM YYYY')}` }}
                  </div>
                  <div v-else-if="$moment(minDate, 'YYYY-MM-DD').format('MMM') !== $moment(maxDate, 'YYYY-MM-DD').format('MMM') && $moment(minDate, 'YYYY-MM-DD').format('YYYY') === $moment(maxDate, 'YYYY-MM-DD').format('YYYY')">
                    {{ `${$moment(minDate, 'YYYY-MM-DD').format('D MMM')} - ${$moment(maxDate).format('D MMM YYYY')}` }}
                  </div>
                  <div v-else>
                    {{ `${$moment(minDate, 'YYYY-MM-DD').format('D MMM YYYY')} - ${$moment(maxDate).format('D MMM YYYY')}` }}
                  </div>
                </v-col>
              </v-row>
              <div class="stats-chart-container">
                <!-- Path : ./components/ChartLimit.vue -->
                <ChartLimit
                  :height="chartData.height"
                  :chartid="chartData.chartid"
                  :annotation-limit="statsEntity.limit"
                  :annotation="chartData.annotation"
                  :annotation-text="chartData.annotationText"
                  :categories="chartData.categories"
                  :series="chartData.series"
                  :limit="statsEntity.limit + 20" />
              </div>
            </div>

            <v-radio-group v-model="rangeLimit" row class="chart-filter-group">
              <v-radio label="1w" value="6" :disabled="loadingData" />
              <v-radio label="1m" value="29" :disabled="loadingData" />
              <v-radio label="3m" value="59" :disabled="loadingData" />
              <v-radio label="6m" value="179" :disabled="loadingData" />
              <v-radio label="1y" value="364" :disabled="loadingData" />
            </v-radio-group>
          </v-card>
        </v-col>
        <!-- End of Static Flight Time -->

        <!-- Flight Time Chart Container -->
        <v-col cols="12" class="d-none text-center pa-0">
          <div v-if="!loadingData && chartData.show && !$store.state.admin" class="download-button-area">
            <v-btn block depressed color="error" class="font-weight-bold shadow-light rounded-pill" style="height: 45px;"
              @click.prevent="!downloadLogbookLoading ? downloadLogbook() : false">
              {{ downloadLogbookLoading ? 'Downloading, Please wait ...' : 'Download Logbook (PDF)' }}
            </v-btn>
          </div>
        </v-col>
        <!-- End of Flight Time Chart Container -->

        <!-- Crew Documents Component -->
        <v-col cols="12" class="pilot-info px-0 pb-0">
          <div class="font-20 fw-600 mb-4">My Documents</div>
          <v-row class="text-left">
            <v-col cols="12" class="pa-0 mb-2">
              <v-card class="card-document d-flex fill-width justify-space-between align-center">
                <div class="pr-2">
                  <div class="font-16">Next Recurrent Date</div>
                  <div class="grey--text">
                    {{ countDays('last_recurrent').date }}
                  </div>
                </div>
                <v-chip :color="countDays('last_recurrent').color" dark small class="rounded-lg px-3 py-4">
                  {{ countDays('last_recurrent').data }}
                </v-chip>
              </v-card>
            </v-col>
            <v-col cols="12" class="pa-0 mb-2">
              <v-card class="card-document d-flex fill-width justify-space-between align-center">
                <div class="pr-2">
                  <div class="font-16">PPC Exp. Date</div>
                  <div class="grey--text text--darken-1">
                    {{ countDays('exp_ppc').date }}
                  </div>
                </div>
                <v-chip :color="countDays('exp_ppc').color" dark small class="rounded-lg px-3 py-4">
                  {{ countDays('exp_ppc').data }}
                </v-chip>
              </v-card>
            </v-col>
            <v-col cols="12" class="pa-0 mb-2">
              <v-card class="card-document d-flex fill-width justify-space-between align-center">
                <div class="pr-2">
                  <div class="font-16">
                    <span class="align-middle mr-2">
                      {{ $store.state.me.license_type === 1 ? 'Indonesian License Exp. Date' : 'Foreign License Exp. Date' }}
                    </span>
                    <span v-if="$store.state.me.license_type === 2" class="align-middle">
                      ({{ $store.state.me.country }})
                    </span>
                  </div>
                  <div class="grey--text text--darken-1">
                    {{ countDays('exp_license').date }}
                  </div>
                </div>
                <v-chip :color="countDays('exp_license').color" dark small class="rounded-lg px-3 py-4">
                  {{ countDays('exp_license').data }}
                </v-chip>
              </v-card>
            </v-col>
            <v-col v-if="$store.state.me.license_type === 2" cols="12" class="pa-0 mb-2">
              <v-card class="card-document d-flex fill-width justify-space-between align-center">
                <div class="pr-2">
                  <div class="font-16">Validation License Exp. Date</div>
                  <div class="grey--text text--darken-1">
                    {{ countDays('exp_validation_license').date }}
                  </div>
                </div>
                <v-chip :color="countDays('exp_validation_license').color" dark small class="rounded-lg px-3 py-4">
                  {{ countDays('exp_validation_license').data }}
                </v-chip>
              </v-card>
            </v-col>
            <v-col v-if="$store.state.me.country !== 'IDN'" cols="12" class="pa-0 mb-2">
              <v-card class="card-document d-flex fill-width justify-space-between align-center">
                <div class="pr-2">
                  <div class="font-16">KITAS Exp. Date</div>
                  <div class="grey--text text--darken-1">
                    {{ countDays('exp_kitas').date }}
                  </div>
                </div>
                <v-chip :color="countDays('exp_kitas').color" dark small class="rounded-lg px-3 py-4">
                  {{ countDays('exp_kitas').data }}
                </v-chip>
              </v-card>
            </v-col>
            <v-col cols="12" class="pa-0 mb-2">
              <v-card class="card-document d-flex fill-width justify-space-between align-center">
                <div class="pr-2">
                  <div class="font-16">
                    {{ $store.state.me.medical_type === 1 ? 'Indonesia Medical Exp. Date' : 'Foreign Medical Exp. Date'}}
                  </div>
                  <div class="grey--text text--darken-1">
                    {{ countDays('exp_med').date }}
                  </div>
                </div>
                <v-chip :color="countDays('exp_med').color" dark small class="rounded-lg px-3 py-4">
                  {{ countDays('exp_med').data }}
                </v-chip>
              </v-card>
            </v-col>
            <v-col v-if="$store.state.me.country !== 'IDN'" cols="12" class="pa-0 mb-2">
              <v-card class="card-document d-flex fill-width justify-space-between align-center">
                <div class="pr-2">
                  <div class="font-16">IMTA Exp. Date</div>
                  <div class="grey--text text--darken-1">
                    {{ countDays('exp_imta').date }}
                  </div>
                </div>
                <v-chip :color="countDays('exp_imta').color" dark small class="rounded-lg px-3 py-4">
                  {{ countDays('exp_imta').data }}
                </v-chip>
              </v-card>
            </v-col>
            <v-col cols="12" class="pa-0 mb-2">
              <v-card class="rounded-lg elevation-1 pa-4 d-flex fill-width justify-space-between align-center">
                <div class="pr-2">
                  <div class="font-16">Security Clearance Exp. Date</div>
                  <div class="grey--text text--darken-1">
                    {{ countDays('exp_security').date }}
                  </div>
                </div>
                <v-chip :color="countDays('exp_security').color" dark small class="rounded-lg px-3 py-4">
                  {{ countDays('exp_security').data }}
                </v-chip>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!-- End of Crew Documents Component -->
      </v-row>

      <v-dialog v-model="dialogProfile.show" fullscreen hide-overlay persistent transition="dialog-bottom-transition" style="z-index: 204!important;">
        <v-card class="profile-info-container">
          <v-toolbar class="profile-info-toolbar">
            <v-btn @click="$router.go(-1)" class="btn-back">
              <v-icon>mdi-chevron-left</v-icon>
              <span class="ml-2">Back</span>
            </v-btn>
          </v-toolbar>
          <v-card class="profile-info-card">
            <div class="font-20 fw-500 text-left mb-4">
              Account Information
            </div>
            <div class="profile-pic-container" @click.prevent="previewPicture = true">
              <div>
                <img :src="$store.state.me.img || '/images/icons/ic-pilot.png'" aspect-ratio="1/1" alt="Avatar" onerror="this.onerror=null;this.src='/images/icons/ic-pilot.png';">
                <div class="font-12 primary--text">
                  <span class="align-middle">
                    <v-icon x-small color="primary">mdi-pencil-outline</v-icon>
                  </span>
                  <span class="align-middle ml-1">Edit</span>
                </div>
              </div>
              <div>
                <div class="profile-title">
                  {{ dialogProfile.dataAccount.name + ' ' + dialogProfile.dataAccount.name_last }}
                </div>
                <div class="profile-caption">
                  Adding a profile picture makes your profile more personalized.
                </div>
              </div>
            </div>
            <v-tabs v-model="tabProfile" centered class="tab-profile">
              <v-tab href="#tab-contact">
                <v-icon>mdi-contact</v-icon>
                Personal Data
              </v-tab>
              <v-tab href="#tab-password">
                Password
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabProfile">
              <v-tab-item key="tab-contact" value="tab-contact">
                <label class="input-label text-left" for="firstName">First Name</label>
                <v-text-field id="firstName" v-model="dialogProfile.dataAccount.name" outlined rounded color="primary"
                  hide-details required readonly disabled class="mb-4"></v-text-field>

                <label class="input-label text-left" for="lastName">Last Name</label>
                <v-text-field if="lastName" v-model="dialogProfile.dataAccount.name_last" placeholder="Last Name" outlined
                  rounded color="primary" hide-details required readonly disabled class="mb-4"></v-text-field>

                <label class="input-label text-left" for="instructor">Instructor</label>
                <v-select v-model.number="dialogProfile.dataAccount.instructor"
                  :items="[{ value: 0, name: 'No' }, { value: 1, name: 'Yes' }]" id="instructor" item-value="value"
                  item-text="name" outlined rounded color="primary" hide-details disabled required
                  :class="dialogProfile.dataAccount.instructor ? 'mb-2' : 'mb-4'"></v-select>

                <div v-if="dialogProfile.dataAccount.instructor" class="instructor-list mb-4">
                  <v-checkbox v-model.number="dialogProfile.dataAccount.instructor_aircraft" label="Aircraft Instructor" color="primary" disabled/>
                  <v-checkbox v-model.number="dialogProfile.dataAccount.instructor_instrument" label="Instrument Instructor" color="primary" disabled/>
                  <v-checkbox v-model.number="dialogProfile.dataAccount.instructor_route" label="Route Instructor" color="primary" disabled/>
                  <v-checkbox v-model.number="dialogProfile.dataAccount.instructor_ground" label="Ground Instructor" color="primary" disabled/>
                </div>

                <label class="input-label text-left" for="gender">Gender</label>
                <v-select v-model="dialogProfile.dataAccount.gender"
                  :items="[{ value: 'male', name: 'Male' }, { value: 'female', name: 'Female' }]" id="gender"
                  item-value="value" item-text="name" outlined rounded color="primary" hide-details disabled required
                  class="mb-4"></v-select>

                <label class="input-label text-left" for="nationality">Nationality</label>
                <v-autocomplete v-model="dialogProfile.dataAccount.country" :items="$store.state.countries.all"
                  id="nationality" item-value="alpha3Code" item-text="name" outlined rounded color="primary" disabled
                  hide-details required class="mb-4"></v-autocomplete>

                <label class="input-label text-left" for="username">Username</label>
                <v-text-field v-model="dialogProfile.dataAccount.username" id="username" outlined rounded color="primary"
                  hide-details disabled required class="mb-4"></v-text-field>

                <label class="input-label text-left" for="phoneNumber">Phone Number</label>
                <v-text-field v-model="dialogProfile.dataAccount.phone" id="phoneNumber" type="tel" outlined rounded
                  color="primary" hide-details required class="mb-4"></v-text-field>

                <label class="input-label text-left" for="email">Email</label>
                <v-text-field v-model="dialogProfile.dataAccount.email" id="email" type="email" outlined rounded
                  color="primary" hide-details required class="mb-4"></v-text-field>

                <!-- Duty Area -->
                <div class="air-list">
                  <div class="title-list">
                    Duty Area
                  </div>
                  <div v-if="$store.state.pilot_aircraft.length >= 1">
                    No Area
                  </div>
                  <div v-for="(area, iArea) in DutyAreas" :key="iArea">
                    {{ area }}
                  </div>
                </div>

                <div class="air-list">
                  <div class="title-list">
                    Aircraft Lists
                  </div>
                  <div v-for="(aircraft, iAircraft) in $store.state.pilot_aircraft" :key="iAircraft">
                    <span class="align-middle">{{ aircraft.fleet_name }}</span>
                    <v-chip v-if="parseInt(aircraft.captain)" x-small color="primary" class="align-middle px-2 ml-2">
                      <v-icon color="white" x-small class="mr-1">
                        mdi-star
                      </v-icon>
                      PIC
                    </v-chip>
                  </div>
                  <div v-if="!$store.state.pilot_aircraft.length">
                    No Aircraft
                  </div>
                </div>
                <div class="air-list">
                  <div class="title-list">
                    Airport(s)
                  </div>
                  <div v-for="(airport, iAirport) in $store.state.pilot_airport" :key="iAirport">
                    {{ airport.airport_iata || '-' }}, {{ airport.airport_icao || '-' }} - {{ airport.airport_cityname }}
                  </div>
                  <div v-if="!$store.state.pilot_airport.length">
                    No Airport
                  </div>
                </div>
                <v-btn block depressed color="error" class="rounded-pill py-6 mt-6 mb-8"
                  :disabled="$store.state.admin ? true : false" @click.prevent="updateProfile()">
                  Update Profile
                </v-btn>
              </v-tab-item>
              <v-tab-item key="tab-password" value="tab-password">
                <label class="input-label text-left" for="oldPassword">Old Password</label>
                <v-text-field
                  v-model="dialogProfile.dataPassword.password_old"
                  id="oldPassword"
                  :type="showPassword.old ? 'text' : 'password'"
                  :append-icon="showPassword.old ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[passwordRules.required, passwordRules.minLength]"
                  outlined
                  rounded
                  color="primary"
                  class="input-password"
                  @click:append="showPassword.old = !showPassword.old"
                />

                <label class="input-label text-left" for="newPassword">New Password</label>
                <v-text-field
                  v-model="dialogProfile.dataPassword.password_new"
                  id="newPassword"
                  :type="showPassword.new ? 'text' : 'password'"
                  :append-icon="showPassword.new ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[passwordRules.required, passwordRules.minLength]"
                  outlined
                  rounded
                  color="primary"
                  class="input-password"
                  @click:append="showPassword.new = !showPassword.new"
                />

                <label class="input-label text-left" for="cNewPassword">Confirm New Password</label>
                <v-text-field
                  v-model="dialogProfile.dataPassword.password_new_confirm"
                  id="cNewPassword"
                  :type="showPassword.confirm ? 'text' : 'password'"
                  :append-icon="showPassword.confirm ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[passwordRules.required, passwordRules.minLength]"
                  outlined
                  rounded
                  color="primary"
                  class="input-password"
                  @click:append="showPassword.confirm = !showPassword.confirm"
                />

                <v-btn block depressed color="error" class="rounded-pill py-6 mt-6"
                  :disabled="$store.state.admin ? true : false" @click.prevent="updatePassword()">
                  Update Password
                </v-btn>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-card>

        <div v-if="previewPicture" class="image-preview animate__animated animate__slideInUp animate__faster">
          <v-btn @click.prevent="previewPicture = false" class="btn-back">
            <v-icon>mdi-chevron-left</v-icon>
              <span class="ml-2">Back</span>
          </v-btn>
          <div class="image-preview-container">
            <img :src="$store.state.me.img || '/images/icons/ic-pilot.png'" alt="Profile Picture" class="profile-image-preview" onerror="this.onerror=null;this.src='/images/icons/ic-pilot.png';">
            <v-btn block depressed color="error" class="rounded-pill py-6 mt-6 mb-8"
              :disabled="$store.state.admin ? true : false" @click.prevent="updateProfilePic()">
              Update Photo
            </v-btn>
          </div>
        </div>

        <input ref="imageUploaderCategory" type="file" class="file-uploader" accept=".png,.gif,.jpeg,.jpg,.heic"
          @change="uploadPhotoRender($event, 'category')">
      </v-dialog>
    </v-container>
  </PullRefresh>
</template>

<script>
import Vue from 'vue'
import { getMessaging, onMessage } from 'firebase/messaging'
import { saveAs } from 'file-saver'
import Swiper from 'swiper'
import { Autoplay, Navigation } from 'swiper/modules'
import 'swiper/swiper-bundle.css'

export default {
  name: 'Home',
  data: (vm) => ({
    adminPilotSelected: 0,
    tabProfile: null,
    showPassword: {
      old: false,
      new: false,
      confirm: false
    },
    passwordRules: {
      required: value => !!value || 'Required',
      minLength: value => value.length >= 8 || 'Min. 8 characters'
    },
    newsData: [],
    limitChart: 10,
    rangeLimit: '6',
    rangeSlider: 13,
    loadingData: false,
    isLoadingStaticChart: false,
    logbookHistory: [],
    selectedLimit: 6,
    showDaysNext: 6,
    loadingSchedule: false,
    maxLimit: 35,
    upcomingSchedule: [],
    statsEntity: {
      title: '7 Days',
      limit: 40
    },
    minDate: Vue.prototype.$moment().subtract(6, 'days').format('YYYY-MM-DD'),
    maxDate: Vue.prototype.$moment().add(6, 'days').format('YYYY-MM-DD'),
    todayLimit: {
      title: 'Today',
      limitHours: 9,
      logbook: 0,
      estimated: 0
    },
    pastLimit: {
      weekly: {
        title: 'Last 7 Days',
        daysValue: 7,
        limitHours: 40,
        logbook: 0,
        estimated: 0
      },
      monthly: {
        title: 'Last 30 Days',
        daysValue: 30,
        limitHours: 100,
        logbook: 0,
        estimated: 0
      },
      month3: {
        title: 'Last 90 Days',
        daysValue: 90,
        limitHours: 300,
        logbook: null,
        estimated: null
      },
      month6: {
        title: 'Last 180 Days',
        daysValue: 180,
        limitHours: 600,
        logbook: null,
        estimated: null
      },
      yearly: {
        title: 'Last 365 Days',
        daysValue: 365,
        limitHours: 1050,
        logbook: null,
        estimated: null
      }
    },
    chartData: {
      show: false,
      chartid: 'chart-logbook',
      height: '320px',
      annotationText: 'Today',
      annotation: '',
      categories: [],
      series: [
        {
          name: 'Logbook',
          color: '#22CCEE',
          data: []
        },
        {
          name: 'Estimate',
          color: '#FFA500',
          data: []
        }
      ]
    },
    dialogProfile: {
      show: false,
      dataAccount: {
        username: '',
        instructor: 0,
        instructor_route: 0,
        name: '',
        name_last: '',
        phone: '',
        email: '',
        gender: 'male',
        country: 'IDN',
        single_crew: 0,
        aircraft: 0,
        duty_area: 0,
        duty_base: 0
      },
      dataPassword: {
        password_old: '',
        password_new: '',
        password_new_confirm: ''
      }
    },
    downloadLogbookLoading: false,
    limitations: [],
    previewPicture: false,
    showForecast: false
  }),
  computed: {
    DutyAreas () {
      const dutyAreas = this.$StrToArray(this.$store.state.me ? (this.$store.state.me.duty_areas || '[]') : '[]')
      const areas = []
      for (let d = 0; d < dutyAreas.length; d++) {
        const foundArea = this.$store.state.logbook.area.find(r => parseInt(r.id) === parseInt(dutyAreas[d]))
        if (foundArea) {
          areas.push(foundArea.name || 'Undefined')
        }
      }
      return areas
    }
  },
  watch: {
    adminPilotSelected: function (v) {
      if (v !== this.$store.state.me.id) {
        this.ChangePilotByAdmin(v)
      }
    },
    rangeLimit: function (v) {
      this.rangeSlider = (v * 2) + 1
      this.loadChartInit(v)
      if (v === '6') {
        this.statsEntity.title = '7 Days'
        this.statsEntity.limit = 40
      } else if (v === '29') {
        this.statsEntity.title = '1 Month'
        this.statsEntity.limit = 100
      } else if (v === '59') {
        this.statsEntity.title = '3 Months'
        this.statsEntity.limit = 300
      } else if (v === '179') {
        this.statsEntity.title = '6 Months'
        this.statsEntity.limit = 600
      } else if (v === '364') {
        this.statsEntity.title = '1 Year'
        this.statsEntity.limit = 1050
      } else {
        this.statsEntity.title = '7 Days'
        this.statsEntity.limit = 40
      }
    },
    $route: {
      immediate: true,
      handler (to) {
        if (to) {
          if (parseInt(to.query.editprofile)) {
            this.dialogProfile.show = true
          } else {
            this.dialogProfile.show = false
          }
        } else {
          this.dialogProfile.show = false
        }
      }
    },
    'dialogProfile.show': function (v) {
      this.dialogProfile.dataAccount.username = this.$store.state.me.username
      this.dialogProfile.dataAccount.instructor = parseInt(this.$store.state.me.instructor) || 0
      this.dialogProfile.dataAccount.instructor_route = parseInt(this.$store.state.me.instructor_route) || 0
      this.dialogProfile.dataAccount.name = this.$store.state.me.name
      this.dialogProfile.dataAccount.name_last = this.$store.state.me.name_last
      this.dialogProfile.dataAccount.phone = this.$store.state.me.phone
      this.dialogProfile.dataAccount.email = this.$store.state.me.email
      this.dialogProfile.dataAccount.gender = this.$store.state.me.gender || 'male'
      this.dialogProfile.dataAccount.country = this.$store.state.me.country || 'IDN'
      this.dialogProfile.dataAccount.single_crew = parseInt(this.$store.state.me.single_crew) || 0
      this.dialogProfile.dataAccount.duty_area = parseInt(this.$store.state.me.duty_area) || 0
      this.dialogProfile.dataAccount.duty_base = parseInt(this.$store.state.me.duty_base) || 0
      this.dialogProfile.dataAccount.aircraft = parseInt(this.$store.state.me.aircraft) || 0
      this.dialogProfile.dataAccount.airport_lists = this.$StrToJson(this.$store.state.me.airport_lists) || []

      this.dialogProfile.dataPassword.password_old = ''
      this.dialogProfile.dataPassword.password_new = ''
      this.dialogProfile.dataPassword.password_new_confirm = ''
    }
  },
  mounted () {
    // Receive Notification from backend
    onMessage(getMessaging(), (payload) => {
      this.onRefresh()
      this.$toastNotif({
        message: payload.notification.body,
        type: 'info'
      })
    })

    this.loadNews()
    this.loadChartInit()
    this.loadStaticChartData()
    this.loadPilotSchedule()
    this.formatDate()
    this.previewPicture = false
    this.adminPilotSelected = this.$store.state.me.id
    this.showForecast = true
  },
  methods: {
    onRefresh () {
      const refreshData = () => {
        this.loadNews()
        this.loadChartInit()
        this.loadStaticChartData()
        this.loadPilotSchedule()
        this.formatDate()
        this.$store.dispatch('ME_GET')
        if (localStorage.getItem('t') && localStorage.getItem('push_token') && !this.$store.state.admin) {
          this.$store.dispatch('CHECK_T', localStorage.getItem('push_token')).then((res) => {
            if (!res) {
              this.$toastNotif({
                message: 'Oops! Looks like your account sneaked onto another device. Please re-login to get back in action!',
                type: 'info',
                timer: 3500
              })
              this.$store.dispatch('DO_LOGOUT')
            }
          })
        }
      }

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          refreshData()
          resolve()
        }, 1000)
      })
    },
    ChangePilotByAdmin (pid) {
      this.$store.dispatch('DO_LOGOUT', true)
      setTimeout(() => {
        this.$store.dispatch('DO_LOGIN_PILOT_BY_ADMIN', pid)
      }, 150)
    },
    Greeting () {
      const hour = this.$moment().format('HH:mm')
      if (hour >= '05:00' && hour <= '12:00') {
        return 'Good Morning'
      } else if (hour > '12:00' && hour <= '18:00') {
        return 'Good Afternoon'
      } else {
        return 'Good Evening'
      }
    },
    async loadNews () {
      await this.$store.dispatch('GET_NEWS').then((res) => {
        if (res.status && res.data.data.response) {
          this.newsData = res.data.data.data
        }
      })
    },
    flightDetail (date) {
      this.$router.push({ path: 'upcoming-schedule', query: { scheduleDate: date } })
    },
    NewLimitation (index, data) {
      if (index === 0 || index > 0) {
        this.limitations[index].id = data.id
        this.limitations[index].name = data.name
      } else {
        this.limitations.push({
          id: parseInt(data.id),
          name: data.name,
          data: []
        })
      }
    },
    AddLimitation (index, data) {
      const foundIndex = this.limitations[index].data.findIndex(r => r.date === data.date)
      if (foundIndex > -1) {
        this.limitations[index].data[foundIndex].data = parseFloat(data.value) || 0
      } else {
        this.limitations[index].data.push({
          date: data.date,
          data: parseFloat(data.value) || 0
        })
      }
    },
    downloadLogbook () {
      this.downloadLogbookLoading = true
      this.$store.dispatch('LOGBOOK_DOWNLOAD', this.$store.state.me.id).then((res) => {
        if (res.status) {
          this.$store.dispatch('LOGBOOK_FETCH_DOWNLOAD', res.data.data.filename).then((r) => {
            if (r) {
              saveAs(r, 'logbook.pdf')
            } else {
              this.$toastNotif({
                message: 'Ops, something went wrong',
                type: 'error'
              })
            }
          })
          // this.$openLink(res.data.data.filename, true, true)
        }
        this.downloadLogbookLoading = false
      })
    },
    formatDate (dateString) {
      const parsedDate = this.$moment(dateString, 'YYYY-MM-DD')
      return parsedDate.format('MMM D YYYY')
    },
    countDays (f) {
      function statColor (days) {
        if (days === '~') {
          return 'st-gray'
        }
        days = parseInt(days) || 0
        if (days < 1) {
          return 'error'
        } else if (days <= 30) {
          return 'error'
        } else if (days <= 60) {
          return 'orange'
        } else {
          return 'success'
        }
      }
      if (this.$store.state.me) {
        if (this.$store.state.me[f]) {
          const d = parseInt(this.$DateRangeDays(this.$localDT(this.$store.state.me[f], 'datedefault'), this.$localDT('', 'datedefault')))
          return {
            color: statColor(d),
            data: d > 0 ? `+${this.$formatNumber(d)} Days` : `${this.$formatNumber(d)} Days`,
            date: this.$localDT(this.$store.state.me[f], 'daydate')
          }
        } else {
          return {
            color: 'st-gray',
            data: '~' + ' day'
          }
        }
      }
    },
    flightSwiper () {
      /* eslint-disable-next-line */
      const upcomingFlightSwiper = new Swiper('.upcomingFlightSwiper', {
        modules: [Autoplay],
        spaceBetween: 16,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true
        }
      })
    },
    newsSwiper () {
      /* eslint-disable-next-line */
      const newsInfoSwiper = new Swiper('.newsInfoSwiper', {
        modules: [Autoplay],
        autoplay: {
          delay: 5500
        },
        spaceBetween: 16,
        centeredSlides: true
      })
    },
    statsSwiper () {
      /* eslint-disable-next-line */
      const statsSwiper = new Swiper('.statsSwiper', {
        modules: [Navigation],
        loop: false,
        spaceBetween: 16,
        navigation: {
          prevEl: '.prev-stats',
          nextEl: '.next-stats'
        }
      })
    },
    async loadInit (v) {
      this.chartData.show = false
      this.chartData.categories = []
      this.chartData.series[0].data = []
      this.chartData.series[1].data = []
      this.chartData.annotation = await this.$DateReformat(this.$TodayDate())

      this.limitations = []
      this.NewLimitation(null, {
        id: parseInt(this.$store.state.me.id),
        name: this.$store.state.me.name
      })
      this.chartData.show = true
    },
    async loadChartInit (l) {
      this.loadingData = true

      this.limitations = []
      this.NewLimitation(null, {
        id: parseInt(this.$store.state.me.id),
        name: this.$store.state.me.name
      })

      this.chartData.show = false
      this.chartData.categories = []
      this.chartData.series[0].data = []
      this.chartData.series[1].data = []

      const limit = parseInt(l) || parseInt(this.rangeLimit)

      await this.loadChartData(limit)
      this.chartData.show = true

      this.loadingData = false
    },
    async loadChartData (limit) {
      this.chartData.show = true
      this.loadingData = true
      this.minDate = this.$moment().subtract(limit, 'days').format('YYYY-MM-DD')
      this.maxDate = this.$moment().add(limit, 'days').format('YYYY-MM-DD')
      const temporaryEstimated = []

      await this.$store.dispatch('PILOT_LOGBOOK_GET', {
        id: this.$store.state.me.id,
        q: `?by=total&rangeLimit=${limit}&rangeShow=${(limit * 2) + 1}&maxDate=${this.maxDate}`
      }).then((res) => {
        if (res.status) {
          res.data.data.map((data) => {
            const dateRange = this.$moment(data.selected_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
            const todayDate = this.$moment().format('YYYY-MM-DD')

            this.chartData.categories.push(dateRange)
            this.chartData.series[0].data.push(dateRange < todayDate ? data.ft_total : 0)
            temporaryEstimated.push(dateRange >= todayDate ? data.ft_total : 0)
          })
        }
      })

      await this.$store.dispatch('PILOT_LOGBOOK_GET', {
        id: this.$store.state.me.id,
        q: `?by=estimate&rangeLimit=0&rangeShow=${(limit * 2) + 1}&maxDate=${this.maxDate}`
      }).then((res) => {
        if (res.status) {
          res.data.data.map((data, index) => {
            const dateRange = this.$moment(data.selected_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
            const todayDate = this.$moment().format('YYYY-MM-DD')
            this.chartData.series[1].data.push(
              dateRange >= todayDate ? temporaryEstimated[index] + data.ft_estimate : 0
            )
          })
        }
      })

      this.chartData.show = false
      this.loadingData = false
    },
    async loadStaticChartData () {
      const dayLimit = [
        { limit: 0 },
        { limit: 6 },
        { limit: 29 }
      ]

      dayLimit.map(async (data) => {
        await this.$store.dispatch('PILOT_LOGBOOK_GET', {
          id: this.$store.state.me.id,
          q: `?by=ft_range&rangeShow=${data.limit}&maxDate=` + this.$moment().format('YYYY-MM-DD')
        }).then((res) => {
          if (res.status) {
            var arrLogbook = []
            var arrEstimate = []
            if (data.limit === 6) {
              res.data.data.map((data) => {
                arrLogbook.push(Number(parseFloat(data.ft_logbook).toFixed(1)))
                arrEstimate.push(Number(parseFloat(data.ft_estimate).toFixed(1)))
              })
              this.pastLimit.weekly.logbook = arrLogbook.reduce((temp, add) => temp + add, 0)
              this.pastLimit.weekly.estimated = arrEstimate.reduce((temp, add) => temp + add, 0)
            } else if (data.limit === 29) {
              res.data.data.map((data) => {
                arrLogbook.push(Number(parseFloat(data.ft_logbook).toFixed(1)))
                arrEstimate.push(Number(parseFloat(data.ft_estimate).toFixed(1)))
              })
              this.pastLimit.monthly.logbook = arrLogbook.reduce((temp, add) => temp + add, 0)
              this.pastLimit.monthly.estimated = arrEstimate.reduce((temp, add) => temp + add, 0)
            } else {
              this.todayLimit.logbook = parseFloat(res.data.data[0].ft_logbook).toFixed(1)
              this.todayLimit.estimated = parseFloat(res.data.data[0].ft_estimate).toFixed(1)
            }
          }
        })
      })
    },
    async toggleLoadStaticChartData (limit) {
      this.isLoadingStaticChart = true
      await this.$store.dispatch('PILOT_LOGBOOK_GET', {
        id: this.$store.state.me.id,
        q: `?by=ft_range&rangeShow=${limit - 1}&maxDate=` + this.$moment().format('YYYY-MM-DD')
      }).then((res) => {
        if (res.status) {
          var arrLogbook = []
          var arrEstimate = []
          if (limit === 90) {
            res.data.data.map((data) => {
              arrLogbook.push(Number(parseFloat(data.ft_logbook).toFixed(1)))
              arrEstimate.push(Number(parseFloat(data.ft_estimate).toFixed(1)))
            })
            this.pastLimit.month3.logbook = arrLogbook.reduce((temp, add) => temp + add, 0)
            this.pastLimit.month3.estimated = arrEstimate.reduce((temp, add) => temp + add, 0)
          } else if (limit === 180) {
            res.data.data.map((data) => {
              arrLogbook.push(Number(parseFloat(data.ft_logbook).toFixed(1)))
              arrEstimate.push(Number(parseFloat(data.ft_estimate).toFixed(1)))
            })
            this.pastLimit.month6.logbook = arrLogbook.reduce((temp, add) => temp + add, 0)
            this.pastLimit.month6.estimated = arrEstimate.reduce((temp, add) => temp + add, 0)
          } else if (limit === 365) {
            res.data.data.map((data) => {
              arrLogbook.push(Number(parseFloat(data.ft_logbook).toFixed(1)))
              arrEstimate.push(Number(parseFloat(data.ft_estimate).toFixed(1)))
            })
            this.pastLimit.yearly.logbook = arrLogbook.reduce((temp, add) => temp + add, 0)
            this.pastLimit.yearly.estimated = arrEstimate.reduce((temp, add) => temp + add, 0)
          }
        }
        this.isLoadingStaticChart = false
      })
    },
    async loadPilotSchedule () {
      await this.$store.dispatch('PILOT_SCHEDULES_GET', {
        id: this.$store.state.me.id,
        q: '?limit=5&sort=ASC&from=' + this.$moment().format('YYYY-MM-DD') + '&to=' + this.$moment().add(7, 'days').format('YYYY-MM-DD')
      }).then((res) => {
        if (res.status) {
          this.upcomingSchedule = res.data?.data?.data
        }
      })
    },
    sumPrevFlighttime (ft, dataRangeSum) {
      let ftSum = 0
      for (let i = 0; i < dataRangeSum.length; i++) {
        ftSum += parseFloat(dataRangeSum[i].flighttime)
      }
      return ftSum
    },
    changeProfilePicture () {
      this.$refs.imageUploaderCategory.click()
    },
    async uploadPhotoRender (event, from) {
      /* eslint-disable */
      const $ = this
      await $.$imgtoBase64(event, async function (res) {
        if (res.status) {
          await $.$store.dispatch('UPLOAD_FILE_DRIVE', {
            file: res.data,
            source: 'pilots'
          }).then((resUpload) => {
            if (resUpload.status) {
              $.$store.dispatch('ME_UPDATE', {
                id: $.$store.state.me.id,
                img: resUpload.data.data.secure_url
              })
            } else {
              $.$toastNotif({
                message: 'Ops, please try again',
                type: 'error'
              })
            }
          })
        } else {
          res && res.data === 'file_size'
            ? $.$toastNotif({
                message: 'Ops, file image is too large. Max size 5mb',
                type: 'error'
              })
            : $.$toastNotif({
                message: 'Ops, file image is too large. Max size 5mb',
                type: 'error'
              })
        }
      })
    },
    updateProfile() {
      this.$SweetAlert({
        title: 'Apply These Changes?',
        caption: 'These changes cannot be reverted.',
        onConfirm: this.proceedUpdateProfile
      })
    },
    proceedUpdateProfile() {
      if (this.dialogProfile.show) {
        if (!this.dialogProfile.dataAccount.name) {
          this.$toastNotif({
            message: 'Name is empty',
            type: 'error'
          })
          return false
        } else if (!this.dialogProfile.dataAccount.name_last) {
          this.$toastNotif({
            message: 'Last name is empty',
            type: 'error'
          })
          return false
        }
        const newProfile = Object.assign({
          id: this.$store.state.me.id
        }, this.dialogProfile.dataAccount)
        delete newProfile.airport_lists

        this.$store.dispatch('ME_UPDATE', newProfile).then((res) => {
          if (res.status) {
            if (res.data.data.id) {
              this.$toastNotif({
                message: 'Profile Updated',
                type: 'success'
              })
              setTimeout(() => {
                if (parseInt(this.$route.query.editprofile)) {
                  this.$router.go(-1)
                }
              }, 500)
            } else {
              this.$toastNotif({
                message: res.data.data.message || 'Something went wrong',
                type: 'error'
              })
            }
          } else {
            this.$toastNotif({
              message: 'Something went wrong',
              type: 'error'
            })
          }
        })
      }
    },
    updatePassword() {
      this.$SweetAlert({
        title: 'Save New Password?',
        caption: 'Your login information will be changed permanently.',
        onConfirm: this.proceedUpdatePassword
      })
    },
    proceedUpdatePassword() {
      if (this.dialogProfile.show) {
        if (!this.dialogProfile.dataPassword.password_old) {
          this.$toastNotif({
            message: 'Please input your old password',
            type: 'error'
          })
          return false
        }
        if (!this.dialogProfile.dataPassword.password_new) {
          this.$toastNotif({
            message: 'Please input your new password',
            type: 'error'
          })
          return false
        }
        if (this.dialogProfile.dataPassword.password_new !== this.dialogProfile.dataPassword.password_new_confirm) {
          this.$toastNotif({
            message: 'New password and confirm password do not match',
            type: 'error'
          })
          return false
        }
        const newPassword = Object.assign({}, this.dialogProfile.dataPassword)

        this.$store.dispatch('ME_UPDATE_PASSWORD', newPassword).then((res) => {
          if (res.status) {
            if (res.data.data.affectedRows) {
              this.$toastNotif({
                message: 'Password Changed',
                type: 'success'
              })
              this.dialogProfile.dataPassword.password_old = ''
              this.dialogProfile.dataPassword.password_new = ''
              this.dialogProfile.dataPassword.password_new_confirm = ''
            } else {
              this.$toastNotif({
                message: res.data.data.message || 'Something went wrong',
                type: 'error'
              })
            }
          } else {
            this.$toastNotif({
              message: 'Something went wrong',
              type: 'error'
            })
          }
        })
      }
    },
    updateProfilePic() {
      this.$SweetAlert({
        title: 'Wanna change your profile picture?',
        caption: 'Hey, how about we switch up the picture or simply take it out for a more professional vibe?',
        confirmText: 'Change Picture',
        onConfirm: this.changeProfilePicture
      })
    },
    async reloadForecast () {
      this.showForecast = false
      const res = await this.$store.dispatch('ME_GET')
      try {
        if (res && res.id) {
          this.$toastNotif({
            message: 'Reload Forecast Successfully',
            type: 'success'
          })
        }
      } catch (error) {
        console.error('Error loading position data : ', error)
      } finally {
        this.showForecast = true
      }
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/global-vars';
@import '../assets/scss/fonts';

.container {
  position: relative;

  &.home {
    background-color: $white;
    padding: 15px 30px 15px;

    &::before {
      content: '';
      background-color: $primary;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 250px;
      border-radius: 0 0 35px 35px;
      z-index: 0;
    }
  }
}

.card-pilot {
  position: relative;
  color: $white;
  @include font-size(12px);
  .pilot-name {
    @include font-size(15px);
  }
  .home-search-pilot {
    padding: 0;
    margin: 0;
    width: auto !important;
    .v-input__slot {
      min-height: unset !important;
      height: auto !important;
      background-color: transparent !important;
      &::before,
      &::after {
        border: 0
      }
      .v-select__slot {
        width: auto;
        input {
          @include line-clamp(1);
          @include font-size(15px);
          color: $white;
          padding: 0;
          width: auto;
          margin-right: 16px;
          &::placeholder {
            color: $white !important
          }
        }
        .v-input__append-inner {
          .v-icon {
            color: $white;
          }
        }
      }
      .v-progress-linear {
        border-radius: 8px;
        width: 190px;
        margin-top: 4px;
      }
    }
  }
  .pilot-hours {
    margin-top: 5px;
    .hours {
      @include font-size(16px);
      font-weight: 700;
    }
  }
  .img {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: $white;
    padding: 3px;
    border-radius: 50%;
    >img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1/1;
    }
    .pilot-status {
      position: absolute;
      top: 0;
      right: 0;
      width: 12px;
      height: 12px;
      background-color: $secondary;
      border-radius: 10px;
      border: 2px solid $white;
      &.off{
        background-color: $danger;
      }
      &.sick{
        background-color: $warning;
      }
    }
  }
}

.upcoming-flights {
  margin-top: 24px;
  padding: 0;
  color: $white;
  z-index: 2;

  a {
    font-weight: 500;
    color: $white !important;
    text-decoration: none;
  }

  .row {
    &:first-of-type {
      margin-bottom: 20px;
    }
  }

  .upcomingFlightSwiper {
    margin: 0 -30px;
    padding: 0 30px 30px;
    .upcoming-flight-card {
      display: flex;
      padding: 12px 10px;
      text-align: center;
      border-radius: 20px;
      border: 1px solid $white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      .flight-date{
        align-self: center;
        width: 80px;
        border-radius: 10px;
        background-color: $primary;
        color: $white;
        padding: 12px 6px;
        .flight-day{
          @include font-size(24px);
          font-weight: 700;
        }
        .flight-month-year{
          @include font-size(13px);
          font-weight: 700;
          margin-bottom: 8px;
        }
        .flight-hour{
          @include font-size(13px);
        }
      }
      >img{
        display: block;
        object-fit: contain;
        align-self: center;
        padding: 0 20px;
      }
      .flight-route-container{
        width: calc(100% - 80px - 46px);
        text-align: left;
        align-self: center;
        .city-departure{
          @include font-size(13px);
          font-weight: 500;
          margin-bottom: 2px;
        }
        .airport-name-iata{
          @include font-size(12px);
          color: #8C8D89;
        }
      }
    }
  }

  .noFlightCard {
    padding: 20px 24px;
    text-align: center;
    border-radius: 20px;
    background-color: $white;
    color: $primary;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    span {
      @include font-size(16px);
      font-weight: 500;
    }
  }
}

.see-more-swiper{
  display: flex;
  height: auto;
  justify-content: center;
  align-items: stretch;
  .more-flight-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px 24px;
    text-align: center;
    border-radius: 20px;
    background-color: #F9F9F9;
    color: #162447;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
    >img{
      display: block;
    }
    >div{
      display: block;
      @include font-size(14px);
      font-weight: 500;
      width: 100%;
    }
  }
}

.news-info {
  margin-top: 16px;
  padding: 0;

  a {
    font-weight: 500;
    text-decoration: none;
  }

  .row {
    &:first-of-type {
      margin-bottom: 20px;
    }
  }

  .newsInfoSwiper {
    margin: 0 -30px;
    padding: 0 30px 30px;
    .newsSlide {
      .news-info-card {
        position: relative;
        height: 15vh;
        min-height: 130px;
        border-radius: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

        >img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          filter: brightness(.75);
          object-fit: cover;
          border-radius: inherit;
          z-index: 2
        }

        >div {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 16px;
          background: #000;
          background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5004595588235294) 35%);
          z-index: 3;
          >div {
            color: $white;
            @include font-size(16px);
            @include line-clamp(2);
          }
        }
      }
    }
  }
  .news-info-blank {
    text-align: center;
    padding: 16px;
    margin-bottom: 30px;
    border-radius: 20px;
    background-color: $white;
    box-shadow: 2px 4px 8px rgba(0,0,0,.1);
    img{
      width: 36%;
      display: block;
      margin: 0 auto 8px;
    }
  }
}

.my-stats {
  padding: 0;
  margin-bottom: 20px;

  .stat-list-card {
    .today-stat {
      padding-bottom: 8px;
      border-bottom: 1px solid #3D3E48;
    }

    .last-month-stat {
      padding-top: 8px;
      .prev-stats, .next-stats {
        position: absolute;
        bottom: 0;
        background-color: $danger;
        border-radius: 50px;
        padding: 0 2px;
        opacity: 1;
        cursor: pointer;
        z-index: 2;
        transition: .3s ease;
        .v-icon {
          color: white;
        }
        &.swiper-button-disabled {
          opacity: .5;
        }
      }
      .prev-stats {
        left: 1px;
      }
      .next-stats {
        left: 27px;
      }
    }

    .limit-flight-hours {
      @include font-size(12px);
      color: #E98862;

      &.limit-reached {
        color: $secondary;
      }
    }
  }

  .stat-list-card,
  .stats-card {
    padding: 16px 24px;
    background-color: $primary;
    color: $white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .loading-container {
      position: relative;
      height: 320px;
      margin: 0 -10px;
      border-bottom: 1px solid #3D3E48;
    }

    .stats-summary-row {
      border-radius: 0 !important;
      border-left: 3px solid #22CCEE;
      padding-left: 12px;
      margin-bottom: 16px;
    }

    .stats-chart-container {
      color: $white;
      min-height: 320px;
      border-top: 1px solid #3D3E48;
      border-bottom: 1px solid #3D3E48;
      margin: 0 -10px !important;
    }

    .chart-filter-group {
      .v-input--radio-group__input {
        justify-content: space-between;

        .v-input--selection-controls__input {
          display: none;
        }

        .v-radio {
          margin: 0 auto !important;

          >label {
            @include font-size(14px);
            font-weight: 700;
            padding: 6px 8px;
            color: $white;
            border-radius: 30px;
          }

          &.v-radio--is-disabled:not(.v-item--active) {
            label {
              color: $grey;
            }
          }

          &.v-item--active {
            label {
              background-color: $danger;
            }
          }
        }
      }

      .v-input__slot {
        margin-bottom: 0;
      }

      .v-messages {
        display: none;
      }
    }
  }
}

.download-button-area {
  position: relative;
  margin-bottom: 25px;
}

.range-select,
.refresh-button {
  position: absolute;
  top: 33px;
  left: 98px;
  z-index: 2;
}

.range-select {
  top: 30px;
  width: 150px;
  left: auto;
  right: 20px;
}

.susiair-chart {
  min-height: 285px;
  background: #fafafa;
  padding: 27px 10px 0px 10px;
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, .1);

  #apexchartschartxlogbook {
    min-height: 285px;
  }
}

.shadow-light {
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, .1) !important;
}

.v-list-item__title {
  text-align: left !important;
}

.pilot-info {
  .card-document {
    padding: 25px 14px;
    border-radius: 10px;
    box-shadow: 1px 5px 40px 8px #6e758812;
    margin-bottom: 8px;
  }

  >.row {
    width: 100%;
    margin: 0px;

    >.pilot-info-cols {
      position: relative;
      padding: 0px 5px 30px 5px;

      >.date {
        position: absolute;
        text-align: center;
        top: 30px;
        left: 0px;
        width: 100%;
        font-size: 9px;
        z-index: 1;

        >span {
          display: block;
          margin: 0 auto;
          background: #010255;
          color: #fff;
          width: 85%;
          border-radius: 0px 0px 10px 10px;
          line-height: 100%;
          padding: 5px 5px 5px 5px;
        }
      }

      .flex-area {
        position: relative;
        border-radius: 40px;
        border: solid 1px #010255;
        background: transparent;
        color: rgb(1, 32, 78);
        padding: 5px 10px 10px 10px;

        .name {
          position: relative;
          font-weight: 600;
          font-size: 10px;

          >span {
            position: absolute;
            bottom: 18px;
            left: 0px;
          }
        }

        .days {
          position: absolute;
          top: -10px;
          right: -1px;
          width: auto;
          padding: 0px 5px;
          background: rgb(182, 182, 182);
          color: #fff;
          font-size: 10px;
          border-radius: 10px;

          &.st-blue {
            background: rgb(1, 64, 158);
          }

          &.st-red {
            background: rgb(218, 2, 2);
          }

          &.st-orange {
            background: rgb(253, 82, 2);
          }

          &.st-yellow {
            background: rgb(161, 159, 8);
          }

          &.st-green {
            background: rgb(42, 129, 1);
          }

          &.st-gray {
            background: rgb(88, 88, 88);
          }
        }
      }
    }
  }
}

.air-list {
  position: relative;
  display: block;
  text-align: left;
  margin-bottom: 12px;

  >div {
    display: inline-block;
    width: auto;
    padding: 8px 16px;
    border: solid 1px $grey;
    border-radius: 20px;
    text-align: left;
    margin-right: 4px;
    margin-bottom: 6px;

    &:not(.title-list) {
      color: $grey;
    }

    &.title-list {
      display: block;
      padding: 0;
      border: 0;
      text-align: left;
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.profile-info-container {
  background-color: $primary !important;
  box-shadow: none !important;

  >.profile-info-toolbar {
    height: auto !important;
    padding: 45px 0 0;
    background-color: $primary !important;
    color: $white !important;
    box-shadow: none !important;

    .v-toolbar__title {
      padding-left: 0 !important;
      @include font-size(16px);
    }
  }

  .profile-info-card {
    padding: 42px 30px 60px;
    border-radius: 50px 50px 0 0;
    background-color: $white;
    box-shadow: none !important;
    min-height: calc(100vh - 101px);

    .v-tabs-items {
      background-color: $white;
    }

    .tab-profile {
      margin: 40px 0 24px;

      .v-tabs-slider-wrapper {
        display: none;
      }

      >.v-tabs-bar {
        background-color: $white;

        .v-tab {
          @include font-size(14px);
          text-transform: capitalize;
          height: 45px;

          &.v-tab--active {
            font-weight: 600;
            background-color: $primary;
            color: $white;
            border-radius: 15px;
          }
        }
      }
    }

    .profile-pic-container {
      display: flex;
      border: 1px solid $grey;
      border-radius: 10px;
      padding: 12px 24px;

      >div:first-of-type {
        position: relative;
        align-self: center;

        img {
          width: 50px;
          height: 50px;
          border: 1px solid $grey;
          border-radius: 50%;
        }

        >.v-icon {
          position: absolute;
          width: 14px;
          height: 14px;
          right: -2px;
          bottom: 8px;
          color: $white;
          background-color: $danger;
          border-radius: 50%;
          padding: 8px;
        }
      }

      >div:last-of-type {
        align-self: center;
        text-align: left;
        padding-left: 20px;

        .profile-title {
          @include font-size(16px);
          font-weight: 500;
          margin-bottom: 2px;
        }

        .profile-caption {
          @include font-size(12px);
          color: $grey;
        }
      }
    }

    .instructor-list{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .v-input--checkbox{
        align-self: center;
        width: 50%;
        margin-top: 0;
        .v-input__slot{
          margin-bottom: 4px;
          .v-input--selection-controls__input{
            margin-right: 6px;
            .v-icon::before{
              @include font-size(20px);
            }
          }
          label{
            @include font-size(14px);
          }
        }
        .v-messages{
          display: none;
        }
      }
    }
  }
}

.image-preview{
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: $primary;
  .btn-back{
    position: absolute;
    left: 16px;
    top: 55px;
  }
  .image-preview-container{
    background-color: $white;
    border-radius: 50px 50px 0 0;
    position: absolute;
    top: 101px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    padding: 50px 30px 50px;
    .profile-image-preview{
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
</style>
